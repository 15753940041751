var mapStyles = [
    {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#444444",
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [
            {
                color: "#f0f0f0",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [
            {
                saturation: -100,
            },
            {
                lightness: 45,
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "all",
        stylers: [
            {
                color: "#0f66aa",
            },
            {
                visibility: "on",
            },
            {
                saturation: "-86",
            },
            {
                lightness: "75",
            },
            {
                gamma: "1.5",
            },
        ],
    },
];

var mainMap = document.getElementById("mainMap");

if (typeof mainMap != "undefined" && mainMap != null) {
    if (mainMap.dataset.mapLat !== undefined) {
        var lat = parseFloat(mainMap.dataset.mapLat);
    }
    if (mainMap.dataset.mapLng !== undefined) {
        var lng = parseFloat(mainMap.dataset.mapLng);
    }
    if (mainMap.dataset.mapZoom !== undefined) {
        var zoom = parseInt(mainMap.dataset.mapZoom);
    } else {
        var zoom = 16;
    }
    if (mainMap.dataset.mapPin !== undefined) {
        var pin = mainMap.dataset.mapPin;
    }

    initMap(lat, lng, zoom);
    initMarker(map, lat, lng, pin);
}

var map;
function initMap(lat, lng, zoom) {
    map = new google.maps.Map(mainMap, {
        center: { lat: lat, lng: lng },
        zoom: zoom,
        scrollwheel: true,
        styles: mapStyles,
    });
}

function initMarker(map, lat, lng, marker) {
    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        icon: marker,
        map: map,
    });
}
