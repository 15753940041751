(function ($) {

    $(document).ready(function () {

        // Inicjalizujemy zmienne
        var $counter = $('.js-counter'),
            countDownDate = parseInt($counter.data('count-date')) * 1000,
            $singleCounterTime = [],
            $singleCounterText = [],
            $singleCounterDayName = [];

        // Pobieramy elementy counter'a tylko raz, żeby nie obciążać skryptu
        $('div', $counter).each(function () {
            $singleCounterTime.push($(this).children().children().next());
            $singleCounterText.push($(this).children().children().next().next());
            $singleCounterDayName.push($(this).find('p'));
        });

        /**
         * Funkcja pomocnicza zwracająca ostatnią cyfrę z liczby na wejściu.
         * @param {int} number 
         * @version 1.0.0
         */
        function getLast(number) {
            return (number.toString().split('').pop()) / 1;
        }

        /**
         * Funkcja odpowiada za działanie counter'a.
         * @version 1.0.0
         */
        function refreshCounter() {
            // Obliczamy różnicę w dacie
            var now = new Date();
            now = now.setHours(now.getHours() + 2),
                distance = countDownDate - now,
                difference = [
                    Math.floor(distance / (1000 * 60 * 60 * 24)), // Dni
                    Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)), // Godzin
                    Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)), // Minut
                    Math.floor((distance % (1000 * 60)) / 1000) // Sekund
                ],
                differenceDivider = [365, 24, 60, 60];
                // console.log(distance);
                console.log(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
                // console.log(now);
                // console.log('countDownDate',countDownDate);
                // console.log('now',now);
                // console.log('distance',distance);

                // Wskakujemy do każdego z czterech counterów i podmieniamy wartości
            if (distance > 0) {
                for (var i = 0; i < 4; i++) {
                    var percent = Math.floor(((difference[i] / differenceDivider[i])) * 100);
                    $singleCounterTime[i].attr('stroke-dasharray', percent + ', 100');
                    $singleCounterText[i].text(difference[i]);

                    switch (i) {
                        // Dni
                        case 0:
                            if (difference[i] == 1) {
                                $singleCounterDayName[i].text('dzień');
                            } else {
                                $singleCounterDayName[i].text('dni');
                            }
                            break;

                            // Godziny
                        case 1:
                            if (difference[i] == 1) {
                                $singleCounterDayName[i].text('godzinę');
                            } else if (2 <= difference[i] && difference[i] <= 4 ||
                                2 <= getLast(difference[i]) && getLast(difference[i]) <= 4 && (difference[i] < 12 || 14 < difference[i])) {
                                $singleCounterDayName[i].text('godziny');
                            } else {
                                $singleCounterDayName[i].text('godzin');
                            }
                            break;

                            // Minuty
                        case 2:
                            if (difference[i] == 1) {
                                $singleCounterDayName[i].text('minutę');
                            } else if (2 <= difference[i] && difference[i] <= 4 ||
                                2 <= getLast(difference[i]) && getLast(difference[i]) <= 4 && (difference[i] < 12 || 14 < difference[i])) {
                                $singleCounterDayName[i].text('minuty');
                            } else {
                                $singleCounterDayName[i].text('minut');
                            }
                            break;

                            // Sekundy
                        case 3:
                            if (difference[i] == 1) {
                                $singleCounterDayName[i].text('sekundę');
                            } else if (2 <= difference[i] && difference[i] <= 4 ||
                                2 <= getLast(difference[i]) && getLast(difference[i]) <= 4 && (difference[i] < 12 || 14 < difference[i])) {
                                $singleCounterDayName[i].text('sekundy');
                            } else {
                                $singleCounterDayName[i].text('sekund');
                            }
                            break;
                    }
                }
            }
            setTimeout(refreshCounter, 1000);
        }
        
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (!isMobile) {
            refreshCounter();
        }
    });

})(jQuery);