(function ($) {

    $(document).foundation();

    $(document).ready(function () {
        $('body').css('opacity', 1);
    });

    // Responsive menu icon/
    var burger = $('#burgerIcon');
    burger.click(function () {
        $(this).toggleClass('burger-icon--open');
    });

    $('.select2').select2({
        minimumResultsForSearch: -1
    });

    lightbox.option({
        'resizeDuration': 0,
        'wrapAround': true,
        'fadeDuration': 150,
        'imageFadeDuration': 150
    });

    var homeSlider = $('#homeSlider');
    if (homeSlider.length > 0) {
        homeSlider.slick({
            'prevArrow': '<span class="slide__arrow slide__arrow--prev"><span class="icon-arrow-left"></span></span>',
            'nextArrow': '<span class="slide__arrow slide__arrow--next"><span class="icon-arrow-right"></span></span>',
        });
    }

    // Register form - Add team member
    $('.js-add-new-team-memeber').on('click', debounce(function () {
        var $teamMembersList = $('.js-team-members'),
            teamMemberId = new Date().getTime(),
            membersCounter = parseInt($teamMembersList.attr('data-members')),
            $newTeamMemberBox =
                '<div class="universal-form__row js-member" data-member-id="' + teamMemberId + '">' +
                '<button class="button tiny alert js-remove-team-member" data-member-id="' + teamMemberId + '">USUŃ</button>' +
                '<div class="input-container">' +
                '<label for="contestant_' + teamMemberId + '_name" class="input__label">Imię i nazwisko</label>' +
                '<input id="contestant_' + teamMemberId + '_name" type="text" required="required" name="user_data[' + teamMemberId + '][name]" class="input" />' +
                '</div>' +
                '<div class="input-container">' +
                '<label for="contestant_' + teamMemberId + '_email" class="input__label">E-mail</label>' +
                '<input id="contestant_' + teamMemberId + '_email" type="email" required="required" name="user_data[' + teamMemberId + '][email]" class="input" />' +
                '</div>' +
                '<div class="input-container">' +
                '<label for="contestant_' + teamMemberId + '_phone" class="input__label">Telefon</label>' +
                '<input id="contestant_' + teamMemberId + '_phone" type="tel" required="required" name="user_data[' + teamMemberId + '][phone]" class="input" />' +
                '</div>' +
                '<div class="input-container">' +
                '<label for="contestant_' + teamMemberId + '_address" class="input__label">Adres</label>' +
                '<input id="contestant_' + teamMemberId + '_address" type="text" required="required" name="user_data[' + teamMemberId + '][address]" class="input" />' +
                '</div>' +
                '<div class="input-container">' +
                '<label for="contestant_' + teamMemberId + '_citizenship" class="input__label">Obywatelstwo</label>' +
                '<input id="contestant_' + teamMemberId + '_citizenship" type="text" required="required" name="user_data[' + teamMemberId + '][citizenship]" class="input" value="Polskie"/>' +
                '</div>' +
                '</div>';

        // Hide fields for probation
        if (membersCounter >= 0) {
            $('.js-only-solo-contestant').hide();
            $('.js-register-probation-checkbox').prop('checked', false);
            $('.js-register-probation').removeClass('is-active');
        }

        // Add new memeber form
        $teamMembersList.append($newTeamMemberBox);

        // Update members counter
        $teamMembersList.attr('data-members', (membersCounter + 1));

    }, 100));

    // Register form - Remove team member
    $(document).on('click', '.js-remove-team-member', function () {
        var id = $(this).attr('data-member-id'),
            $teamMembersList = $('.js-team-members'),
            membersCounter = parseInt($teamMembersList.attr('data-members'));

        // Remove fields
        $('.js-member[data-member-id="' + id + '"]').remove();

        // Show field for probation
        if (membersCounter == 1) {
            $('.js-only-solo-contestant').show();
        }

        // Update members counter
        $teamMembersList.attr('data-members', (membersCounter - 1));
    });

    $('.js-register-probation-checkbox').on('change', function () {
        if ($(this).prop('checked')) {
            $('.js-register-probation').addClass('is-active');
        } else {
            $('.js-register-probation').removeClass('is-active');
        }
    });

    var $projectStatus = $('#js-edit-project-status');

    // Ajax form
    $(document).on('submit', '.js-ajax-form', function (e) {
        // Prevent sending our form
        e.preventDefault();

        var form = $(this);

        // Check options
        var resetForm = $(this).attr('data-reset-on-success');
        var voteForm = $(this).attr('data-voteform');
        var voteFormEnableChange = $(this).attr('data-voteformenablechange');
        resetForm = (typeof resetForm !== typeof undefined && resetForm !== false);
        var redirectOnSuccess = $(this).attr('data-redirect-on-success');
        if (redirectOnSuccess = (typeof redirectOnSuccess !== typeof undefined && redirectOnSuccess !== false || '')) redirectUrl = $(this).attr('data-redirect-on-success');

        // serialize the form data
        var ajax_form_data = $(this).serialize();
        var modal = $('#js-flash-modal');
        var modal_content = $('#js-flash-modal-content');
        var modal_close = $('.js-ajax-modal-close');

        if (voteForm == 'jury') {

            var team = form.attr('data-team');
            var vote = form.find('select option:selected').text();
            var voteInt = parseInt(vote);
            if (voteInt == 1) {
                $points = "punkt";
            } else if ((voteInt == 2) || (voteInt == 3) || (voteInt == 4)) {
                $points = "punkty";
            } else {
                $points = "punktów";
            }

            var status = confirm("Czy napewno chcesz dać " + vote + " " + $points + " dla projektu #" + team + "?");
            
            if (status == false) {
                e.target.reset();
                return false;
            }

        }

        $.ajax({
            url: ajax.url,
            type: 'post',
            data: ajax_form_data,
            dataType: 'json',
            beforeSend: function () {
                modal_close.css({
                    'opacity': '0',
                    'pointer-events': 'none'
                });
                modal_content.html('<div class="js-loader"><div></div><div></div><div></div><div></div></div>'); // set modal content to loading
                modal.foundation('open'); // show modal
            },
            success: function (r) {
                // SUCCESS
                if (r.success) {
                    if (resetForm) e.target.reset(); // reset form
                    if (redirectOnSuccess) {
                        setTimeout(function () {
                            window.location.href = redirectUrl; // redirect after success
                        }, 1000);
                    }
                    if (r.redirect) {
                        setTimeout(function () {
                            window.location.href = r.redirect; // redirect after success -- from response
                        }, 1000);
                    }
                    // Update username
                    if (r.update_username !== false) $('.js-username').html(r.update_username);
                    if (voteForm == 'jury' && !voteFormEnableChange) {
                        form.find('select').parent().html(vote);
                    }
                    if ($projectStatus.length > 0) { 
                        refresh_project_status(form.find('input[name="project_id"]').val());
                    }
                }
                // FAIL
                else {
                    if (r.reset_on_fail) e.target.reset(); // reset form
                }
                // MODAL
                modal_content.html(''); // reset modal content
                if (!$.isArray(r.msg)) {
                    modal_content.append('<div>' + r.msg + '</div>');
                } else {
                    $.each(r.msg, function (key, value) {
                        modal_content.append('<div>' + value + '</div>');
                    });
                }
                modal_close.css({
                    'opacity': '1',
                    'pointer-events': 'all'
                });
                $('.reveal-overlay').bind('click', revealModal);
            },
            error: function (xhr, error) {
                console.debug(xhr);
                console.debug(error);
            }
        });
        return false;

    });

    function refresh_project_status(id) { 
        $.ajax({
            url: ajax.url,
            type: 'post',
            data: {
                action: 'refresh_project_status',
                project_id: id
            },
            dataType: 'json',
            beforeSend: function () {
                $projectStatus.html('<div class="js-loader"><div></div><div></div><div></div><div></div></div>');
            },
            success: function (r) {
                $projectStatus.html(r);
            }
        });
    }

    function revealModal() {
        $('#js-flash-modal').foundation('close');
        $('.reveal-overlay').unbind('click', revealModal);
    }

    $('input[type="checkbox"]').on('change', function () {
        var checked = $(this).prop('checked');
        var name = $(this).attr('name');
        $('label[data-for="' + name + '"] input[type="checkbox"]').prop('checked', checked);
    });

    var fbVoteModal = $('#fbVoteModal');
    var resultsModal = $('#competitionResultsModal');
    if (fbVoteModal.length > 0) {
        if (fbVoteModal.attr('data-reveal-on-load') == "true") {
            fbVoteModal.foundation('open'); // show modal
        }
    }
    if (resultsModal.length > 0) {
        resultsModal.foundation('open');
    }

    if ($('.js-reveal-on-load').length > 0) {
        $('.js-reveal-on-load').foundation('open'); // show modal
    }

    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
        if ($('.js-reveal-on-load-no-mobile').length > 0) {
            $('.js-reveal-on-load-no-mobile').foundation('open'); // show modal
        }
    }

    $('.js-ajax-form-graphic').on('change', '[type="file"]', function () {
        if ($(this).val() != '' && $(this).val() != null) {
            $(this).parents('form:first').submit();
        }
    });

    $('.js-select-file').on('click', function (e) { 
        e.preventDefault();
        // console.log($('#' + $(this).attr('data-file')));
        $('#' + $(this).attr('data-file')).trigger('click');
    });

    // Ajax form
    $('.js-ajax-form-graphic').submit(function (e) {
        // Prevent sending our form
        e.preventDefault();

        // Catch form
        var $form = $(this);
        var modal = $('#js-flash-modal');
        var modal_content = $('#js-flash-modal-content');
        var modal_close = $('.js-ajax-modal-close');

        // serialize the form data
        var form_data = new FormData();
        form_data.append('action', $form.find('input[name="action"]').val());
        form_data.append('_wp_http_referer', $form.find('input[name="_wp_http_referer"]').val());
        form_data.append('nonce_add_graphic_to_project', $form.find('input[name="nonce_add_graphic_to_project"]').val());
        form_data.append('project_id', $form.find('input[name="project_id"]').val());
        form_data.append('graphic_id', $form.find('input[name="graphic_id"]').val());
        form_data.append('file', $form.find('input[type="file"]').prop('files')[0]);

        $.ajax({
            url: ajax.url,
            type: 'post',
            contentType: false,
            processData: false,
            data: form_data,
            dataType: 'json',
            xhr: function ()
            {
              var xhr = new window.XMLHttpRequest();
              xhr.upload.addEventListener("progress", function(evt){
                if (evt.lengthComputable) {
                  var percentComplete = parseInt((evt.loaded / evt.total) * 100);
                  if(percentComplete == 100){
                    $(document).find('#ajax-progress').html('<span style="font-size: 14px;">Grafika została przesłana na serwer. Trwa przetwarzanie...</span>');
                  } else {
                    $(document).find('#ajax-progress').html(percentComplete + '%');
                  }
                }
              }, false);
              return xhr;
            },
            beforeSend: function () {
                modal_close.css({
                    'opacity': '0',
                    'pointer-events': 'none'
                });
                modal_content.html('<div class="js-loader"><div></div><div></div><div></div><div></div></div><div id="ajax-progress"></div>'); // set modal content to loading
                modal.foundation('open'); // show modal
            },
            success: function (r) {
                if (r.success) {
                    e.target.reset(); // reset form
                    if (r.img) {
                        var unique = $.now();
                        $form.find('.graphic, .graphic--edit').attr('src', r.img + '?' + unique).removeClass('graphic--placeholder');
                    }
                }
                modal_content.html(''); // reset modal content
                // fill modal
                if (!$.isArray(r.msg)) {
                    modal_content.append('<div>' + r.msg + '</div>');
                } else {
                    $.each(r.msg, function (key, value) {
                        modal_content.append('<div>' + value + '</div>');
                    });
                }
                modal_close.css({
                    'opacity': '1',
                    'pointer-events': 'all'
                });
                $('.reveal-overlay').bind('click', revealModal);
                if ($projectStatus.length > 0) { 
                    refresh_project_status($form.find('input[name="project_id"]').val());
                }
            },
            error: function (xhr, error) {
                console.debug(xhr);
                console.debug(error);
            }
        });
        return false;

    });

    $('.js-submit-form-on-change').on('change', function () {
        $(this).closest('form').submit();
    });

    var $paginatorFixed = $('#paginator-fixed');
    if ($('#projects-container').length > 0) { 
        var originalOffset = $('#projects-container').offset().top;
    }

    $(window).on('scroll', function () {
        var scrollTop = $(this).scrollTop();

        if (scrollTop > originalOffset - 20) {
            $('#paginator-fixed').addClass('is-fixed');
        } else {
            $('#paginator-fixed').removeClass('is-fixed');
        }
    });

    $(window).on('resize', function () {
        setTimeout(function () {
            if ($('#projects-container').length > 0) { 
                originalOffset = $('#projects-container').offset().top;
            }
        })
    })

    $('.js-facebook-login').on('click', function () {

        // Catch form
        var modal = $('#js-flash-modal');
        var modal_content = $('#js-flash-modal-content');
        var modal_close = $('.js-ajax-modal-close');
        var redirect = $(this).attr('data-redirect');
        var form_data = new FormData();
        form_data.append('action', 'kolo_fb_login');

        FB.login(function (response) {
            if (response.authResponse) {
                $.ajax({
                    url: ajax.url,
                    type: 'post',
                    contentType: false,
                    processData: false,
                    data: form_data,
                    dataType: 'json',
                    beforeSend: function () {
                        modal_close.css({
                            'opacity': '0',
                            'pointer-events': 'none'
                        });
                        modal_content.html('<div class="js-loader"><div></div><div></div><div></div><div></div></div>'); // set modal content to loading
                        modal.foundation('open'); // show modal
                    },
                    success: function (r) {
                        modal_content.html(''); // reset modal content
                        // fill modal
                        if (!$.isArray(r.msg)) {
                            modal_content.append('<div>' + r.msg + '</div>');
                        } else {
                            $.each(r.msg, function (key, value) {
                                modal_content.append('<div>' + value + '</div>');
                            });
                        }
                        modal_close.css({
                            'opacity': '1',
                            'pointer-events': 'all'
                        });
                        $('.reveal-overlay').bind('click', revealModal);
                        if (redirect) { 
                            window.location.href = redirect;
                        }else if (r.reload == true) {
                            var url = window.location.href;    
                            if (url.indexOf('?') > -1){
                                url += '&fb'
                            }else{
                                url += '?fb'
                            }
                            window.location.href = url;
                        };
                    },
                    error: function (xhr, error) {
                        console.debug(xhr);
                        console.debug(error);
                    }
                });

            } else {
                modal_content.html(''); // reset modal content
                modal_content.append('<div>Użytkownik anulował logowanie do Facebooka.</div>');
                modal_close.css({
                    'opacity': '1',
                    'pointer-events': 'all'
                });
                $('.reveal-overlay').bind('click', revealModal);
                modal.foundation('open'); // show modal
            }
        }, {
            scope: 'email'
        });
        return false;
    });

    $(document).on('click', '[toggle-readmore]', function (e) { 
        e.preventDefault();
        var id = $(this).attr('href');
        $(id).slideDown();
        $(this).remove();
    });

    $(document).on('submit', '#mda_file_consent_1', function (e) {
        // Prevent sending our form
        e.preventDefault();

        // Check agreement
        if(!$('#agreement_file_1').is(':checked')){
            return;
        }
        
        $.ajax({
            url: ajax.url,
            type: 'post',
            data: {
                action: 'agreement_ajax',
                user_checked: 'true'
            },
            dataType: 'json',
            success: function (r) {
                location.reload();
            },
            error: function (xhr, error) {
                console.debug(xhr);
                console.debug(error);
            }
        });
        return false;

    });

    $(document).on('submit', '#consent-4-gateway', function (e) {
        // Prevent sending our form
        e.preventDefault();

        // Check consent
        if(!$('#consent-4-gateway-input').is(':checked')){
            return;
        }
        
        $.ajax({
            url: ajax.url,
            type: 'POST',
            data: {
                action: 'consent_4_ajax',
                consent: 1,
            },
            dataType: 'json',
            success: function (r) {
                location.reload();
            },
            error: function (xhr, error) {
                console.debug(xhr);
                console.debug(error);
            }
        });
        return false;

    });

    $(document).on('submit', '.js-location-form', function (e) {

        e.preventDefault();

        var $form = $(this);

        var form_data = new FormData();
        form_data.append('action', $form.find('input[name="action"]').val());
        form_data.append('name', $form.find('input[name="name"]').val());
        form_data.append('phone', $form.find('input[name="phone"]').val());
        form_data.append('email', $form.find('input[name="email"]').val());
        form_data.append('file', $form.find('input[type="file"]').prop('files')[0]);
        form_data.append('message', $form.find('textarea[name="message"]').val());
        form_data.append('link', $form.find('input[name="link"]').val());
        form_data.append('street', $form.find('input[name="street"]').val());
        form_data.append('city', $form.find('input[name="city"]').val());
        form_data.append('state', $form.find('input[name="state"]').val());
        form_data.append('institution', $form.find('input[name="institution"]').val());

        $form.append('<div class="js-loader-container"><div class="js-loader"><div></div><div></div><div></div><div></div></div></div>').addClass('form-loading');

        $.ajax({
            url: ajax.url,
            type: 'POST',
            contentType: false,
            processData: false,
            data: form_data,
            dataType: 'json',
            
            beforeSend: function () {
                $('.js-cf-response').remove();
            },
            success: function (response) {
                $form.removeClass('form-loading').find('.js-loader-container').remove();

                $form.find('.input-container').each(function() {
                    $(this).removeClass('input-container--error');
                })

                if (response.status == 'ERROR') { 
                    $form.append("<p class='js-cf-response form-output form-output--errors'>" + response.msg + "</p>");
                    $.each(response.errors.errors, function (key, value) {
                        $form.find( '[name="' + key + '"]').parents('.input-container').addClass('input-container--error');
                        $( "<p class='input__error js-cf-response'>" + value + "</p>" ).insertAfter( $form.find( '[name="' + key + '"]') );
                    });
                } else {
                    $form.append("<p class='js-cf-response form-output form-output--success'>" + response.msg + "</p>");
                    $.each($form.find('input[type="text"], input[type="email"], input[type="tel"], input[type="file"], textarea'), function () {
                        $(this).val('');
                    });
                }
            }
        });
    });


})(jQuery);

document.querySelectorAll('ul .tabs__item .tabs__link').forEach((tab) => {
    tab.addEventListener('click', () => {
        document.querySelectorAll('.tabs-content .is-active').forEach((e) => {e.classList.remove('is-active')});
        document.querySelectorAll('ul .tabs__item .tabs__link').forEach((tab) => {
            tab.setAttribute('aria-selected', false);
        });
    })
});